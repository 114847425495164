import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Popover,
  Slider,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { formatNumber } from '../../utils/core'
import Analytics from '../../backend/analytics'
import Cart from '../../backend/cart'
import Colors from '../../utils/colors'
import Divider from '../divider'
import { CustomIcon, IconWrapper } from '../icon'
import Button from '../button'
import Row from '../row'
import Quantity from './quantity'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(1),
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
  },
  donation: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(1.5),
    '& .MuiTypography-overline': {
      lineHeight: 'inherit',
    },
  },
  slider: {
    margin: '40px 20px 0px 20px',
    width: 'inherit',
  },
  receipt: {
    margin: theme.spacing(1),
  },
  receiptHeader: {
    fontWeight: 'bold',
  },
  receiptRow: {
    display: 'flex',
    flexFlow: 'row',
    '& p': {
      fontSize: 'small',
    },
  },
  receiptLabel: {
    minWidth: 150,
  },
  receiptValue: {
    flex: 1,
    textAlign: 'right',
  },
  buttonContainer: {
    margin: theme.spacing(1),
  },
  button: {
    minWidth: 200,
    maxWidth: 500,
    justifyContent: 'center',
  },
  loading: {
    height: 'inherit',
    width: 'inherit',
  },
  helperText: {
    fontSize: 'small',
    color: Colors.get('grey', 700),
    paddingLeft: theme.spacing(1),
    whiteSpace: 'wrap',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  transparency: {
    boxShadow: 'none',
    color: Colors.get('grey', 700),
  },
  list: {
    paddingInlineStart: '20px',
  },
}))

const GreenCheckbox = withStyles({
  root: {
    color: Colors.get('green', 400),
    '&$checked': {
      color: Colors.get('green', 600),
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function InstantCheckout({
  buttonCTA,
  buttonProps,
  campaignID,
  className,
  disableCheckout,
  maximumQuantity,
  organization,
  pricing,
  subscriptionEnabled,
}) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [checkoutInProgress, setCheckoutInProgress] = React.useState(false)
  const [donation, setDonation] = React.useState(0)
  const [donationSubscription, setDonationSubscription] = React.useState(false)
  const [extraInformationOpened, setExtraInformationOpened] = React.useState(
    false
  )
  const [quantity, setQuantity] = React.useState(1)
  // eslint-disable-next-line no-unused-vars
  const [subscribe, setSubscribe] = React.useState(false)
  const [summary, setSummary] = React.useState(null)

  // delete if nessecary i just want to comeback to review once commpleteing the react fundamentals
  /*  React.useEffect(() => {
    setDonationSubscription(subscriptionEnabled)
  }, [subscriptionEnabled]) */
  React.useEffect(() => {
    if (pricing) updateSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricing])

  const openPopover = event => {
    setAnchorEl(event.currentTarget)
    if (campaignID) {
      Analytics.AddToCart({
        campaigns: [campaignID],
        value: _.get(pricing, 'one_time'),
      })
    }
  }
  const closePopover = () => {
    setAnchorEl(null)
  }
  const toggleAccordion = () => {
    setExtraInformationOpened(!extraInformationOpened)
  }

  function checkout() {
    setCheckoutInProgress(true)
    if (campaignID) {
      Cart.addItem(campaignID, pricing.one_time, quantity, subscribe)
    }
    Cart.addDonation(campaignID, donation, donationSubscription)
    Cart.checkout().catch(() => {
      _.delay(setCheckoutInProgress, 1000, false)
    })
  }
  function shouldDisableCheckout() {
    return (
      disableCheckout ||
      _.get(summary, 'total') === 0 ||
      (!campaignID && donation === 0)
    )
  }

  function updateQuantity(newQuantity) {
    setQuantity(newQuantity)
    updateSummary(null, newQuantity)
  }
  function updateSlider(event, newDonation) {
    setDonation(newDonation)
    if (newDonation === 0) setDonationSubscription(false)
    updateSummary(newDonation, null)
  }
  function updateDonationSubscription(event, value) {
    setDonationSubscription(value)
  }
  const updateSummary = _.throttle((inputDonation, inputQuantity) => {
    if (!campaignID) return // Skip summary for donation-only views
    const donationToUse = _.isNumber(inputDonation) ? inputDonation : donation
    const quantityToUse = _.isNumber(inputQuantity) ? inputQuantity : quantity
    const price = subscribe ? pricing.recurring : pricing.one_time
    setSummary(Cart.instantCheckoutSummary(price, quantityToUse, donationToUse))
  }, 500)

  return (
    <Grid container className={className}>
      <Row
        justify="center"
        ignoreDefaultStyle={true}
        className={classes.buttonContainer}
      >
        <Button
          className={classes.button}
          disabled={disableCheckout}
          onClick={openPopover}
          size="large"
          {...buttonProps}
        >
          {buttonCTA}
        </Button>
      </Row>

      <Backdrop className={classes.backdrop} open={anchorEl !== null}>
        <Popover
          anchorReference={'none'}
          open={!!anchorEl}
          onClose={closePopover}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.root}
          PaperProps={{
            className: classes.container,
          }}
        >
          {/** PRODUCT */}
          {campaignID ? (
            <>
              <Row spreadOut>
                <Grid item className={classes.quantity}>
                  <Typography variant="subtitle1">Quantity</Typography>
                </Grid>
                <Grid item className={classes.quantity}>
                  <Quantity
                    value={quantity}
                    max={maximumQuantity}
                    setter={updateQuantity}
                  />
                </Grid>
              </Row>

              <Divider />
            </>
          ) : null}

          {/** DONATION */}
          <Grid className={classes.donation}>
            <Typography variant="overline">
              {campaignID
                ? `Add an additional donation`
                : `Choose your donation amount`}
            </Typography>
            <Slider
              value={donation}
              valueLabelFormat={newValue =>
                formatNumber(newValue, { isCurrency: true })
              }
              onChange={updateSlider}
              step={5}
              valueLabelDisplay="on"
              className={classes.slider}
            />
            {subscriptionEnabled ? (
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={donationSubscription}
                    onChange={updateDonationSubscription}
                    disabled={donation === 0}
                  />
                }
                label={`Donate ${
                  organization ? `to ${organization.name}` : ''
                } monthly`}
                className={classes.helperText}
              />
            ) : null}
          </Grid>

          {/** RECEIPT */}
          {summary ? (
            <>
              <Divider />
              <Grid className={classes.receipt}>
                <Typography
                  variant="overline"
                  className={classes.receiptHeader}
                >
                  Summary
                </Typography>
                <Grid className={classes.receiptRow}>
                  <Typography className={classes.receiptLabel}>
                    Stickers ({_.get(summary, ['product', 'quantity'])})
                  </Typography>
                  <Typography
                    className={classes.receiptValue}
                    style={{
                      color:
                        _.get(summary, ['product', 'total']) > 0
                          ? 'inherit'
                          : Colors.get('grey'),
                    }}
                  >
                    {formatNumber(_.get(summary, ['product', 'total']), {
                      includeDecimal: true,
                      isCurrency: true,
                    })}
                  </Typography>
                </Grid>
                <Grid className={classes.receiptRow}>
                  <Typography className={classes.receiptLabel}>
                    Additional Donation{' '}
                    {donationSubscription ? ` (monthly)` : ''}
                  </Typography>
                  <Typography
                    className={classes.receiptValue}
                    style={{
                      color:
                        _.get(summary, 'donations') > 0
                          ? 'inherit'
                          : Colors.get('grey'),
                    }}
                  >
                    {formatNumber(_.get(summary, 'donations'), {
                      includeDecimal: true,
                      isCurrency: true,
                    })}
                  </Typography>
                </Grid>
                <Grid className={classes.receiptRow}>
                  <Typography
                    className={classes.receiptLabel}
                    gutterBottom={true}
                  >
                    Shipping
                  </Typography>
                  <Typography
                    className={classes.receiptValue}
                    style={{
                      color:
                        _.get(summary, 'shipping') > 0
                          ? 'inherit'
                          : Colors.get('grey'),
                    }}
                  >
                    {formatNumber(_.get(summary, 'shipping'), {
                      includeDecimal: true,
                      isCurrency: true,
                    })}
                  </Typography>
                </Grid>
                <Grid className={classes.receiptRow}>
                  <Typography
                    className={classes.receiptLabel}
                    style={{ fontWeight: 'bold' }}
                  >
                    Subtotal
                  </Typography>
                  <Typography className={classes.receiptValue}>
                    {formatNumber(_.get(summary, 'total'), {
                      includeDecimal: true,
                      isCurrency: true,
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Row justify="center">
                <CustomIcon
                  icon={new IconWrapper('shipping')}
                  color={Colors.get('lightGreen')}
                />
                <Typography align="center" className={classes.helperText}>
                  Free shipping on 2 or more stickers!
                </Typography>
              </Row>
            </>
          ) : null}

          {/** CHECKOUT */}
          <Button
            onClick={checkout}
            size="small"
            disabled={shouldDisableCheckout() || checkoutInProgress}
            variant="contained"
            color="amber"
          >
            {checkoutInProgress ? (
              <CircularProgress className={classes.loading} />
            ) : (
              'Checkout'
            )}
          </Button>

          {/** TRANSPARENCY */}
          <Accordion
            expanded={extraInformationOpened}
            onChange={toggleAccordion}
            className={classes.transparency}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Transparency</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className={classes.list}>
                <li>
                  <Typography className={classes.helperText}>
                    {organization.name} gets 100% of{' '}
                    {campaignID ? ' proceeds and ' : ''} donations, less
                    standard transaction fees
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.helperText}>
                    Your donor information may be shared with{' '}
                    {organization.name} so that they can provide a
                    tax-deductible receipt
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.helperText}>
                    Recurring donations can be canceled at any time
                  </Typography>
                </li>
                {pricing ? (
                  <li>
                    <Typography className={classes.helperText}>
                      Shipping may take up to 2 weeks after close of campaign
                    </Typography>
                  </li>
                ) : null}
              </ul>
            </AccordionDetails>
          </Accordion>
        </Popover>
      </Backdrop>
    </Grid>
  )
}

InstantCheckout.propTypes = {
  buttonCTA: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
  campaignID: PropTypes.number,
  className: PropTypes.string,
  disableCheckout: PropTypes.bool,
  maximumQuantity: PropTypes.number,
  organization: PropTypes.object.isRequired,
  pricing: PropTypes.object,
  subscriptionEnabled: PropTypes.bool,
}

InstantCheckout.defaultProps = {
  buttonProps: {},
  campaignID: null,
  disableCheckout: false,
  maximumQuantity: 25,
  subscriptionEnabled: false,
}

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'

import Colors from '../../utils/colors'
import { IconWrapper } from '../icon'
import IconButton from '../icon_button'

const MIN_VALUE = 0

const useStyles = makeStyles(theme => ({
  box: {
    border: '1px solid lightgrey',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  text: {
    width: theme.spacing(6),
    '& input': {
      textAlign: 'center',
    },
  },
}))

export default function Quantity({ value, max, setter }) {
  const classes = useStyles()

  function decrement() {
    setter(Math.max(MIN_VALUE, value - 1))
  }

  function increment() {
    if (max === null || max === undefined) {
      setter(value + 1)
    } else setter(Math.min(value + 1, max))
  }

  function decrementDisabled() {
    return value <= MIN_VALUE
  }

  function incrementDisabled() {
    if (max === null) return false
    return value >= max
  }

  function ensureDefault() {
    if (_.toInteger(value) <= 0) setter(1) // No blanks
  }

  function validateInput(e) {
    if (e.target.value === '') return setter(e.target.value)
    const newQuantity = _.toInteger(e.target.value)
    if (newQuantity > MIN_VALUE) {
      if (max === null || newQuantity <= max) setter(newQuantity)
    }
  }

  return (
    <Box className={classes.box}>
      <IconButton
        icon={new IconWrapper('minus')}
        onClick={decrement}
        disabled={decrementDisabled()}
        color={decrementDisabled() ? Colors.get('grey') : 'initial'}
      />
      <TextField
        value={value}
        onChange={validateInput}
        onBlur={ensureDefault}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          'aria-label': 'naked',
        }}
        className={classes.text}
      />
      <IconButton
        icon={new IconWrapper('plus')}
        onClick={increment}
        disabled={incrementDisabled()}
        color={incrementDisabled() ? Colors.get('grey') : 'initial'}
      />
    </Box>
  )
}

Quantity.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  setter: PropTypes.func.isRequired,
}

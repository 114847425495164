import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  ListItemText,
  Typography,
} from '@material-ui/core'

import { formatNumber } from '../../utils/core'
import Colors from '../../utils/colors'
import Divider from '../divider'
import Status from '../status'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    padding: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  listText: {
    alignItems: 'center',
    fontFamily: 'Karla',
  },
  dollarAmount: {
    backgroundColor: Colors.get('blue', 600),
    fontSize: 'large',
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(1),
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Karla',
  },
}))

export default function CampaignImpact({ raised, tiers }) {
  const classes = useStyles()

  return (
    <>
      <List
        className={classes.root}
        subheader={
          <ListSubheader>
            <Typography
              variant="h6"
              fontFamily="prompt"
              className={classes.header}
            >
              Our Collective Effort's Impact
            </Typography>
          </ListSubheader>
        }
      >
        {_.map(tiers, (tier, idx) => {
          return (
            <div key={idx}>
              <ListItem alignItems="flex-start" className={classes.listText}>
                <ListItemAvatar>
                  {raised ? (
                    <Status
                      color={
                        raised >= tier.amount
                          ? Colors.get('green')
                          : Colors.get('amber')
                      }
                      pulse={true}
                    >
                      <Avatar alt="Amount" className={classes.dollarAmount}>
                        {formatNumber(tier.amount, { isCurrency: true })}
                      </Avatar>
                    </Status>
                  ) : (
                    <Avatar alt="Amount" className={classes.dollarAmount}>
                      {formatNumber(tier.amount, { isCurrency: true })}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      ></Typography>
                      {tier.impact}
                    </React.Fragment>
                  }
                />
              </ListItem>
              {idx < tiers.length - 1 ? <Divider /> : null}
            </div>
          )
        })}
      </List>
    </>
  )
}

CampaignImpact.propTypes = {
  raised: PropTypes.number,
  tiers: PropTypes.array.isRequired,
}

CampaignImpact.defaultProps = {
  raised: 0,
}

import _ from 'lodash'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Grid, ListItemText, Typography } from '@material-ui/core'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import Colors from '../../utils/colors'
import Row from '../row'

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'center',
  },
  summary: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bullets: {
    display: 'flex',
    flexFlow: 'column',
  },
  bullet: {
    display: 'flex',
    flexFlow: 'row',
    marginBottom: theme.spacing(1.75),
    color: Colors.get('grey', 700),
  },
  emoji: {
    marginRight: theme.spacing(1),
    backgroundColor: 'white',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexFlow: 'column',
  },
  video: {
    width: '100%',
    maxWidth: '700px',
  },
  mobileVideo: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export default function OrganizationHighlights({
  className,
  highlights,
  video,
}) {
  const classes = useStyles()

  return (
    <Grid container className={className}>
      <Row className={classes.container}>
        {video ? (
          <>
            <Grid className={classes.video}>
              <Grid className={classes.mobileVideo}>
                <LiteYouTubeEmbed id={video} adNetwork={false} />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Typography variant="body1" className={classes.summary}>
          {highlights ? highlights.summary : null}
        </Typography>
        <Grid className={classes.bullets}>
          {_.map(highlights.list, (highlight, idx) => {
            return (
              <Grid key={idx} className={classes.bullet}>
                <Avatar className={classes.emoji}>
                  {String.fromCodePoint(highlight.emoji)}
                </Avatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {highlight.title}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={highlight.subtitle}
                />
              </Grid>
            )
          })}
        </Grid>
      </Row>
    </Grid>
  )
}

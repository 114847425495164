import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { IconWrapper } from '../components/icon'
import Analytics from '../backend/analytics'
import Campaign from '../backend/campaign'
import CampaignDetails from '../components/campaign/campaign_details'
import CampaignTitle from '../components/campaign/campaign_title'
import Chip from '../components/chip'
import Colors from '../utils/colors'
import Divider from '../components/divider'
import ImageGallery from '../components/campaign/image_gallery'
import CampaignImpact from '../components/campaign/campaign_impact'
import InstantCheckout from '../components/campaign/instant_checkout'
import OrganizationHighlights from '../components/campaign/organization_highlights'
import OrganizationPopover from '../components/campaign/organization_popover'
import Page from './page'
import PreorderTimer from '../components/campaign/preorder_timer'
import { NoEncryption } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    width: 'inherit',
    maxWidth: '530px',
  },
  imagePanel: {
    display: 'flex',
    flexGrow: 1,
    /* tag right click disable - unsure if we even need this - if you right click on image you cant save or copy it, it saves html */
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    justifyContent: 'center',
    maxWidth: 'min-content',
    margin: theme.spacing(1.5),
    '& p': {
      marginTop: theme.spacing(1),
      color: Colors.get('grey'),
    },
  },
  images: {
    display: 'flex',
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: '500px',
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
      height: '50vh',
    },
    [theme.breakpoints.up('sm')]: {
      width: '40em',
      height: '40em',
    },
  },
  impacts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  details: {
    flex: 1,
    justifyContent: 'end',
    flexWrap: 'nowrap',
    padding: theme.spacing(0),
  },
  mainpanel: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    marginBottom: theme.spacing(5),
  },
  tags: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  tag: {
    margin: theme.spacing(1),
  },
  timer: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    width: 'inherit',
    maxWidth: '530px',
  },
  video: {
    margin: theme.spacing(1),
    width: '100%',
    maxWidth: '700px',
  },
  supportButton: {
    margin: theme.spacing(1),
  },
  divider: {
    maxWidth: '1000px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobilevid: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopvid: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

function CampaignPage({ location }) {
  const classes = useStyles()

  const [ID, setID] = React.useState(null)
  const [allowPurchase, setAllowPurchase] = React.useState(true)
  const [deadline, setDeadline] = React.useState(null)
  const [description, setDescription] = React.useState(null)
  const [goal, setGoal] = React.useState(0)
  const [images, setImages] = React.useState([])
  const [highlights, setHighlights] = React.useState([])
  const [inventory, setInventory] = React.useState(0)
  const [organization, setOrganization] = React.useState(null)
  const [pricing, setPricing] = React.useState(null)
  const [progress, setProgress] = React.useState(0)
  const [raised, setRaised] = React.useState(0)
  const [sold, setSold] = React.useState(0)
  const [subscriptionEnabled, setSubscriptionEnabled] = React.useState(false)
  const [tags, setTags] = React.useState([])
  const [title, setTitle] = React.useState(null)

  React.useEffect(() => {
    const URL_ID = _.toInteger(_.get(queryString.parse(location.search), 'id'))
    Campaign.get(URL_ID).then(campaign => {
      if (!campaign) return

      setID(URL_ID)
      setAllowPurchase(campaign.allowablePurchase)
      setDeadline(campaign.deadline)
      setDescription(campaign.productType)
      setGoal(campaign.adjustedGoal)
      setHighlights(campaign.highlights)
      setImages(campaign.images)
      setInventory(campaign.inventory)
      setOrganization(campaign.organization)
      setPricing(campaign.pricing)
      setRaised(campaign.raised)
      setSold(campaign.sold)
      setSubscriptionEnabled(campaign.subscriptionEnabled)
      setTags(campaign.tags)
      setTitle(campaign.title)

      if (goal !== null) {
        setProgress(Math.round((campaign.sold / campaign.adjustedGoal) * 100))
      }

      Analytics.ViewContent({
        campaigns: [URL_ID],
        value: _.get(campaign, ['pricing', 'one_time']),
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid>
          {title ? (
            <CampaignTitle title={title} pricing={pricing} />
          ) : (
            <Skeleton variant="text" className={classes.spacer} />
          )}
          {/** MAIN CONTENT */}
          <Grid className={classes.mainpanel}>
            <Grid container className={classes.imagePanel}>
              {images.length ? (
                <ImageGallery images={images} className={classes.images} />
              ) : (
                <Skeleton variant="rect" width={210} height={200} />
              )}
              <Typography variant="body1">{description}</Typography>
            </Grid>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Grid>
                  <OrganizationPopover
                    includeLabel={true}
                    organization={organization}
                  />
                  <OrganizationHighlights
                    highlights={highlights}
                    video={_.get(organization, 'video')}
                  />
                  <CampaignDetails
                    className={classes.details}
                    sold={sold}
                    goal={goal}
                    raised={raised}
                    progress={progress}
                  />
                  {organization && pricing ? (
                    <InstantCheckout
                      buttonCTA="Support"
                      buttonProps={{
                        variant: 'contained',
                        color: 'amber',
                      }}
                      campaignID={ID}
                      disableCheckout={!allowPurchase}
                      organization={organization}
                      pricing={pricing}
                      subscriptionEnabled={subscriptionEnabled}
                    />
                  ) : null}
                  {organization ? (
                    <InstantCheckout
                      buttonCTA="Give Directly"
                      buttonProps={{
                        variant: 'contained',
                        color: 'cyan',
                      }}
                      organization={organization}
                      subscriptionEnabled={subscriptionEnabled}
                    />
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid className={classes.tags}>
          {_.map(tags, (tag, idx) => {
            const name = _.get(tag.icon, 'name')
            const library = _.get(tag.icon, 'library')
            const icon = name ? new IconWrapper(name, library) : null
            const color = _.get(tag, 'color')
            return (
              <Chip
                key={idx}
                className={classes.tag}
                color={color}
                label={tag.name}
                icon={icon}
              />
            )
          })}
        </Grid>
        {deadline ? (
          <Grid className={classes.timer}>
            <PreorderTimer deadline={deadline} />
          </Grid>
        ) : (
          <Skeleton variant="text" width={200} />
        )}
        <Divider className={classes.divider} />
        <Grid className={classes.impacts}>
          {organization /* && organization.video */ ? (
            <>
              <Grid className={classes.video}>
                <Grid className={classes.desktopvid}>
                  <Typography variant="h6">
                    About {organization ? organization.name : null}
                  </Typography>
                  <LiteYouTubeEmbed id={organization.video} adNetwork={false} />
                </Grid>
              </Grid>
            </>
          ) : null}
          {organization && organization.tiers ? (
            <Grid>
              <CampaignImpact raised={raised} tiers={organization.tiers} />
            </Grid>
          ) : null}
          {organization && pricing ? (
            <InstantCheckout
              className={classes.supportButton}
              buttonCTA="Support"
              buttonProps={{
                variant: 'contained',
                color: 'amber',
              }}
              campaignID={ID}
              disableCheckout={!allowPurchase}
              organization={organization}
              pricing={pricing}
              subscriptionEnabled={subscriptionEnabled}
            />
          ) : null}
        </Grid>
      </Grid>
    )
  }

  return (
    <Page
      content={content()}
      title={title ? `${title} | Made to Uplift` : null}
      showCart={false}
    />
  )
}

export default withSnackbar(CampaignPage)

import React from 'react'

import { Grid } from '@material-ui/core'

import Box from '../box'
import Campaign from '../../backend/campaign'
import TitledBox from '../titled_box'

export default function PreorderTimer({ deadline }) {
  const [active, setActive] = React.useState(true)
  const [days, setDays] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const [minutes, setMinutes] = React.useState(0)
  const [seconds, setSeconds] = React.useState(0)

  React.useEffect(() => {
    if (deadline !== null) {
      setInterval(() => {
        setActive(Campaign.active)
        const duration = Campaign.timeRemaining(deadline)
        setDays(duration.days)
        setHours(duration.hours)
        setMinutes(duration.minutes)
        setSeconds(duration.seconds)
      }, 1000)
    }
  }, [deadline])

  function plural(number, unit) {
    if (number === 1) return unit
    return `${unit}s`
  }

  if (active)
    return (
      <TitledBox
        title="Preorder ends in"
        color="red"
        notes={[`${deadline.format('ddd, MMM Do')} at 11:59 PM CST`]}
      >
        <Grid item>
          <Box title={days} subtitle={plural(days, 'day')} />
        </Grid>
        <Grid item>
          <Box title={hours} subtitle={plural(hours, 'hour')} />
        </Grid>
        <Grid item>
          <Box title={minutes} subtitle={plural(minutes, 'minute')} />
        </Grid>
        <Grid item>
          <Box title={seconds} subtitle={plural(seconds, 'second')} />
        </Grid>
      </TitledBox>
    )
  return null
}
